import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import { get, times, capitalize, startCase } from 'lodash'
import Layout from '../components/theme/layout'
import PostCard from '../components/contentful/post-card'
import SEO from '../components/seo'
import styles from '../scss/blog.scss'
import { ButtonGroup } from 'react-bootstrap'
import { blogReviewsListUrl, blogPostCategoryLabels, findAncestorByClassNameInEventPath, buildBreadcrumbsItem, blogReviewUrl } from "../helpers.js"
import { ALL_BLOG_CATEGORIES_LABEL } from "../constants.js"

class BlogReviewsListTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.siteUrl = get(this.props, 'data.site.siteMetadata.siteUrl')
    this.page = get(this.props, 'pageContext.page', 1)
    this.category = get(this.props, 'pageContext.category', ALL_BLOG_CATEGORIES_LABEL) || ALL_BLOG_CATEGORIES_LABEL
    this.pages = get(this.props, 'pageContext.pages', 1)
    this.posts = get(this.props, 'pageContext.reviews', [])

    this.allCategories = get(this.props, 'pageContext.allCategories', [])
    const capitalizedCategory = capitalize(this.category)
    this.breadcrumbs = [
      buildBreadcrumbsItem('Reviews', '/reviews/')
    ]

    if (this.category !== ALL_BLOG_CATEGORIES_LABEL) {
      this.breadcrumbs.push(buildBreadcrumbsItem(`${capitalizedCategory}${this.page > 1 ? ' - Page ' + this.page : ''}`, this.props.location.href))
    }

    this.seo = {
      title: 'Happy Tails',
      description:  'Learn how to take better care of your fur baby and improve their overall health.',
      disallowIndexing: true,
      children: []
    }

    this.state = {
      filtersOpen: false
    }

    this.seo.paginationAttributes = {
      currentLink: blogReviewsListUrl(this.category, this.page, this.siteUrl)
    }
    if (this.pages > 1 && this.page > 1 ) {
      this.seo.paginationAttributes.prevLink = blogReviewsListUrl(this.category, this.page - 1, this.siteUrl)
    }
    if (this.pages > 1 && this.page < this.pages ) {
      this.seo.paginationAttributes.nextLink = blogReviewsListUrl(this.category, this.page + 1, this.siteUrl)
    }

    this.handleFiltersButtonChange = this.handleFiltersButtonChange.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  /**
   * Handles click event so we can close the cart when it a click is made outside it
   * @param  {event} e
   * @return {void}
   */
  handleDocumentClick(e) {
    // These are all the classes that will prevent this from happening
    if (!findAncestorByClassNameInEventPath(e, ['filter-btn-group-container-inner']) && this.state.filtersOpen) {
      this.handleFiltersButtonChange()
    }
  }

  /**
   * Open/Closes filter button
   * @return {[type]}
   */
  handleFiltersButtonChange() {
    this.setState({
      filtersOpen: !this.state.filtersOpen
    })
  }

  render() {
    const allCategories = [ALL_BLOG_CATEGORIES_LABEL].concat(this.allCategories)
    const prevLink = (this.pages > 1 && this.page > 1 ) ? blogReviewsListUrl(this.category, this.page - 1) : null
    const nextLink = (this.pages > 1 && this.page < this.pages ) ? blogReviewsListUrl(this.category, this.page + 1) : null
    return (
      <Layout location={this.props.location} containerClassName={`pb-5 blog ${this.category ? this.category : ''} blog-${this.page}`}
              location={ this.props.location } breadcrumbs={ this.breadcrumbs }>
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <div className="filter-btn-group-container text-center page-header">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 mx-lg-auto text-center">
                <h1 className="mb-1 mb-lg-3">Happy Tails</h1>
                {/*<div className="filter-btn-group-container-inner">*/}
                  {/*<button className='btn btn-primary btn-block mt-4 rounded-0 d-xl-none' onClick={this.handleFiltersButtonChange}>View Filters</button>*/}
                  {/*<ButtonGroup className={`flex-column flex-xl-row  filter-btn-group ${this.state.filtersOpen ? 'open' : ''}`}>*/}
                    {/*{allCategories.map( (category, index) => {*/}
                      {/*let currentCategory = category.category || category*/}
                      {/*let buttonClass = 'btn-outline-primary'*/}
                      {/*const link = blogReviewsListUrl(this.category)*/}
                      {/*if (currentCategory === this.category) {*/}
                        {/*buttonClass = 'btn-primary'*/}
                      {/*}*/}

                      {/*return <Link to={link} className={ `btn ${buttonClass}` } key={ index }>{ startCase(currentCategory) }</Link>*/}
                    {/*})}*/}
                  {/*</ButtonGroup>*/}
                {/*</div>*/}
                <div className="happy-tails-description">
                  Here at Honest Paws, we're proud to be a part of the wellness journey for countless dogs and cats across the nation! Here are just some of the customer success stories we've heard from pet parents who've used Honest Paws products to support their animal friend's health and happiness.
                </div>
                <Link to="/reviews/" className="btn btn-success mt-4">View Reviews</Link>
              </div>
            </div>
          </div>

        </div>
        <div className="container">
          <div className="row mt-5 d-flex justify-content-center">
            {this.posts.map((post, index) => {
              return <PostCard
                title={ post.node.title } url={ blogReviewUrl(post.node) } image={ post.node.heroImage }
                category={ post.node.category }
                key={ index }
                className="col-12 col-sm-6 col-md-4 col-xl-3 mb-5 text-color-inverse" />
            })}
          </div>
          <div className="paginator-container mt-5 text-center">
            {prevLink ? <Link to={ prevLink } className="btn btn-link d-md-none mr-3">Prev</Link> : ''}
            {nextLink ? <Link to={ nextLink } className="btn btn-link d-md-none">Next</Link> : ''}
            <ButtonGroup className="d-none d-md-block">
              {prevLink ? <Link to={ prevLink } className="btn btn-link btn-prev">Prev</Link> : ''}
              { this.pages > 1 ? times(this.pages, (index) => {
                const page = index + 1
                let buttonClass = 'btn-link'
                if (this.page === page) {
                  buttonClass += ' active'
                }
                const link = blogReviewsListUrl(this.category, page)
                return <Link to={ link } className={ `btn ${buttonClass}` } key={ index }>{ page }</Link>
              }) : ''}
              {nextLink ? <Link to={ nextLink } className="btn btn-link btn-next">Next</Link> : ''}
            </ButtonGroup>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogReviewsListTemplate

export const BlogReviewsListTemplateQuery = graphql`
  query BlogReviewsListTemplateQuery {
    site {
      siteMetadata { 
        siteUrl
      }
    }
    allContentfulBlogReview {
      edges {
        node {
          disallowIndexing
          title
          slug
          heroImage {
            file {
              url
            }
            description
            contentful_id
          }
        }
      }
    }
  }
`